#embedded{
    
    text-align: center;

}
/* General styles */
#embedded {
    font-family: Arial, sans-serif;
    background-color: #F8F9F9;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #D5D8DC;
    max-width: 800px;
    margin: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

#embedded_iframe {
    height: 600px;
}
  
  /* Section header */
  #embedded header h1 {
    font-size: 1.5em;
    color: #212F3C;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Article header */
  #embedded article header h2 {
    font-size: 1.2em;
    color: #2C3E50;
    margin-bottom: 15px;
    border-bottom: 1px solid #D5D8DC;
    padding-bottom: 5px;
  }
  
  /* Embedded elements container */
  #embedded article div {
    margin: 15px 0;
  }
  
  /* Images and captions */
  #embedded__images img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 10px auto;
    border-radius: 4px;
    border: 1px solid #D5D8DC;
  }
  
  figure {
    margin: 10px auto;
    text-align: center;
  }
  
  figcaption {
    font-size: 0.9em;
    color: #7F8C8D;
    margin-top: 5px;
  }
  
  /* Audio and video players */
  audio,
  video {
    display: block;
    margin: 10px auto;
    width: 100%;
    max-width: 600px;
    border: 1px solid #D5D8DC;
    border-radius: 4px;
    background-color: #F8F9F9;
  }
  
  /* Canvas */
  canvas {
    display: block;
    margin: 10px auto;
    width: 100%;
    max-width: 600px;
    border: 1px solid #D5D8DC;
    border-radius: 4px;
    background-color: #FFFFFF;
  }
  
  /* Meter and progress */
  meter,
  progress {
    display: block;
    margin: 10px auto;
    width: 100%;
    max-width: 600px;
    height: 20px;
    border-radius: 4px;
    background-color: #F8F9F9;
  }
  
  meter::-webkit-meter-bar,
  progress::-webkit-progress-bar {
    background-color: #E5E8E8;
    border-radius: 4px;
  }
  
  meter::-webkit-meter-optimum-value,
  progress::-webkit-progress-value {
    background-color: #5DADE2;
    border-radius: 4px;
  }
  
  /* SVG */
  svg {
    display: block;
    margin: 10px auto;
    width: 100%;
    max-width: 150px;
  }
  
  /* IFrame */
  iframe {
    display: block;
    margin: 10px auto;
    width: 100%;
    border: 1px solid #D5D8DC;
    border-radius: 4px;
  }
  
  /* Footer */
  #embedded article footer p {
    text-align: center;
    font-size: 0.9em;
    margin-top: 20px;
  }
  
  #embedded article footer a {
    color: #3498DB;
    text-decoration: none;
  }
  
  #embedded article footer a:hover {
    text-decoration: underline;
  }
  