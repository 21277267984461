
#textheader{
    position: relative;
    top: 60px;
    left: 500px;
    font-size: 40px;
    
}

.headingArticle{
    position: relative;
    text-align: center;
    top: 80px;
    height: 340px;
    width: 90%;
    background-color: rgba(240, 233, 221, 0.824);
    border-radius: 10px;
    left: 30px;
    
}

#text_paragraphs{
    position: relative;
    top: 80px;
}

#text_blockquotes{
    position: relative;
    top: 80px;
}

#text_lists{
    position: relative;
    top: 80px;
}

#text_hr{
    top: 70px;
    margin-top: 170px;
}


#tableheader{
    text-align: center;
    display: inline;
}

#text_table{
    position: relative;
    text-align: center;    
    left: 250px;
}

#table_footers{
    position: relative;
    text-align: center;
}

.articlefooters{
   
    text-align: center;
}


