.textsection {
  position: relative;
  top: 40px;
  list-style-type: none;
  text-align: justify;
}

.textsubsection {
  position: relative;
  display: block;
  top: 10px;
  left: 0px;
  list-style-type: none;
  padding: 2px 2px 2px 2px;
}

#text_navheader {
  position: relative;
  font-size: 16px;
  text-decoration: none;
  top: 0px;
  left: 70px;
  color: white;
}

.embeddsection {
  position: absolute;
  top: 120px;
  list-style-type: none;
  text-align: justify;
  left: 500px;
}

.embeddsubsection {
  position: relative;
  top: 10px;
  list-style-type: none;
  text-align: justify;
  padding: 4px 4px 4px 4px;
}

.formsection {
  position: absolute;
  top: 120px;
  list-style-type: none;
  text-align: justify;
  left: 800px;
}

.formsubsection {
  position: relative;
  top: 20px;
  list-style-type: none;
  text-align: justify;
  padding: 4px 4px 4px 4px;
}

#embedd_navheader {
  position: relative;
  font-size: 15px;
  text-decoration: none;
  width: 20px;
  left: 80px;
  color: white;
}

#form_navheader {
  position: relative;
  font-size: 15px;
  text-decoration: none;
  top: 0px;
  left: 100px;
  color: white;
}

.links {
  color: #333;
  font-weight: 500;
}
