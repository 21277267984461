.HeaderLinks {
  text-align: center;
  text-decoration: none;
  color: white;
}

.List:hover {
  background-color: rgb(154, 112, 75);
  color: white;
}

.header {
  background-color: yellow;
}

.head {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}

.arrow {
  color: aliceblue;
}

.login {
  color: aliceblue;
}

.menu {
  display: block;
  position: absolute !important;
  background-color: #e0e7ff;
  top: 100%;
  color: #444;
  z-index: 40;
  border-radius: 10px;
}

.menuitems {
  text-decoration: none;
  text-align: center;
  color: #444;
  font-size: 14px;
  text-transform: capitalize;
}

.initpop {
  display: none;
}
