
.iframe {
    position: relative;
    top: 120px;
    left: 200px;
    border: 2px solid black;
    width: 400px;
    height: 300px;

}

.click {
    position: relative;
    top: -50px;
    left: 10px;

}

.initpop{
  display: none;
}

.popup{
    display: block;
    position: absolute;
    height: 400px;
    width: 500px;
    left:190px;
    background-color: black;
    border: 5px black;
    bottom: 100px;
    opacity: 0.2;
    z-index: 1;
    top: 80px;

}
