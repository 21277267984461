/* General styles */
#forms {
    font-family: Arial, sans-serif;
    background-color: #F8F9F9;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #D5D8DC;
    max-width: 600px;
    margin: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Form header */
  #forms header h1 {
    font-size: 1.5em;
    color: #212F3C;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Fieldset styles */
  fieldset {
    border: none;
    margin-bottom: 20px;
  }
  
  legend {
    font-size: 1.2em;
    color: #212F3C;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Label and input styles */
  label {
    display: block;
    font-size: 0.9em;
    color: #212F3C;
    margin-bottom: 5px;
  }
  
  input,
  select,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 0.9em;
    border: 1px solid #D5D8DC;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  
  input:focus,
  select:focus,
  textarea:focus {
    border-color: #5DADE2;
    outline: none;
    box-shadow: 0px 0px 4px rgba(93, 173, 226, 0.6);
  }
  
  textarea {
    resize: none;
  }
  
  /* Checkbox and radio styles */
  .MuiFormControlLabel-root {
    margin-bottom: 10px;
  }
  
  .MuiFormLabel-root {
    color: #212F3C;
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  
  /* Action button styles */
  button,
  input[type="button"],
  input[type="submit"],
  input[type="reset"] {
    padding: 10px 20px;
    font-size: 0.9em;
    color: #FFFFFF;
    background-color: #5DADE2;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  button:hover,
  input[type="button"]:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover {
    background-color: #3498DB;
  }
  
  button:disabled,
  input[type="button"]:disabled,
  input[type="submit"]:disabled,
  input[type="reset"]:disabled {
    background-color: #D5D8DC;
    cursor: not-allowed;
  }
  
  /* Error and valid states */
  .is-error {
    border-color: #E74C3C;
    background-color: #FDEDEC;
  }
  
  .is-valid {
    border-color: #2ECC71;
    background-color: #E8F8F5;
  }
  