.font {
    margin-top: 60px;
        background-color: #FDF2E9;
        /*text-decoration-line: line-through;*/
        text-align: center;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
        

.background {
    background-color: #f9f3ee;
}