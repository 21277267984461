.article_background {
    padding: 8px 16px;
    background: white;
    margin-top: 32px;
    margin-bottom: 60px;
    border-radius: 16px;
  }

#preformat_text{
    position: relative;  
    text-align: center;
    
  }